
<template>
    <div class="form-component">
        <label 
            v-if="label"
            :for="randomId"
            class="flex-none">
            
            {{label}}
            
            <span 
                v-if="required"
                class="required">
                * 
                
                <!-- <span 
                    v-if="showRequiredWarning && (value == '' || value == [])" 
                    class="large">
                    Required field
                </span>
                <span 
                    v-else-if="!showRequiredWarning && (value == '' || value == [])" 
                    class="small">
                    *
                </span> -->
            </span>
        </label>
        
        <textarea 
            :name="name" 
            :placeholder="placeholder" 
            :id="randomId"
            :value="value"
            @input="$emit('input', $event.target.value)"
            :required="required"
            class="flex-grow"
            :class="classes"
        />
    </div>
</template>

<script>
    export default {
        props: [
            'name',
            'label',
            'value',
            'classes',
            'required',
            'placeholder',
            'showRequiredWarning',
        ],
        
        data(){
            return {
                randomId: null
            }
        },
        
        mounted(){
            const numString = ''+Math.random()*10000;
            this.randomId = numString.replace('.','');
        }
    }
</script>
